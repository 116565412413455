@tailwind base;
@tailwind components;
@tailwind utilities;

/*=============== VARIABLES CSS ===============*/
:root {
  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/
  --white-color: hsl(0, 0%, 100%);
  --black-color: hsl(0, 0%, 0%);
  --chakra-colors-brand-500: "#5BC8AC !important";
  /*========== Font and typography ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  --body-font: "Poppins", sans-serif;
  --h1-font-size: 1.75rem;
  --normal-font-size: 1rem;
  --small-font-size: .813rem;
  /*========== Font weight ==========*/
  --font-medium: 500;
}

.css-bo31hw {
  font-weight: var(--chakra-fontWeights-medium);
  font-size: var(--chakra-fontSizes-sm);
  line-height: 50%;
  color: var(--chakra-colors-secondaryGray-600);
}

.css-bo31hw:first-child {
  font-weight: var(--chakra-fontWeights-medium);
  font-size: 1.3rem !important;
  line-height: 50%;
  color: var(--chakra-colors-secondaryGray-600);
  text-align: center;
  font-weight: 500 !important;
  margin-bottom: 1.2rem;
}
.top-stat:first-of-type{
  font-size: 1.3rem !important;
}

.team-header{
  color: #5bc8ac !important;
}

.member-name{
  color: #888 !important;
}

.top-stat{
  font-size: 1rem !important;
}

.bg_modal{
  opacity: 0.92;
  height: 100%;
}

.leftside_img{
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.login{
  width: 100%;
  height: 100vh;
}

.video{
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.content{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white
}

.login_title{
  font-size: 5.5rem;
  font-weight: 200;
  letter-spacing: 1px;
}

.login_subtitle{
  margin-top: -10px;
  font-size: 1.1rem;
  font-weight:lighter;
  letter-spacing: .5px;
}

.login_btn{
  margin-top: 4rem;
  font-size: 1.8rem !important;
  font-weight: 200 !important;
}

.modal-content{
  max-height: 440px;
}

.modal-body{
  padding: 0 !important;
  overflow: hidden;
}

.contact-text{
  font-weight: 200;
  font-size: .8rem !important;
}

.user-input{
  padding: 0 !important;
}

.login-subtitle{
  font-size: 1rem !important;
  font-weight: 200 !important;
}

.remember-container{
  border: 2px solid red;
}





/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body,
input,
button {
  font-size: var(--normal-font-size);
  font-family: var(--body-font);
}

body {
  color: #333
}

input,
button {
  border: none;
  outline: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

/*=============== LOGIN FORM===============*/

.modal-dialog .modal-lg .modal-dialog-centered{
  max-height: 440px !important;
}
.login_alt {
  position: relative;
  height: 100vh;
  display: grid;
  align-items: center;
}
/* .login__img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
} */
.login__form {
  background-color: transparent;
  height: 100%;
  width: 100%;
  padding: 2.5rem 1.5rem;
  border-radius: 1rem;
  backdrop-filter: blur(8px);
}

.form-container{
  height: 100% !important;
  display: flex;
}

.login__title {
  text-align: center;
  color: #333 !important;
  font-size: var(--h1-font-size);
  font-weight: 700;
  margin-bottom: 2rem;
}
.login__content, .login__box {
  display: grid;
}
.login__content {
  row-gap: 1.75rem;
  margin-bottom: 1.5rem;
}
.login__box {
  grid-template-columns: max-content 1fr;
  align-items: center;
  column-gap: 0.75rem;
  border-bottom: 2px solid #333;
}
.login__icon, .login__eye {
  font-size: 1.25rem;
  color: #333;
}
.login__input {
  width: 100%;
  padding-block: 0.8rem;
  background: none;
  color: #333;
  position: relative;
  z-index: 1;
}
.login__box-input {
  position: relative;
}
.login__label {
  position: absolute;
  left: 0;
  top: 13px;
  font-weight: var(--font-medium);
  transition: top 0.3s, font-size 0.3s;
  color: #333;
}
.login__eye {
  position: absolute;
  right: 0;
  top: 18px;
  z-index: 10;
  cursor: pointer;
}
.login__box:nth-child(2) input {
  padding-right: 1.8rem;
}
.login__check, .login__check-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.login__check {
  margin-bottom: 1.5rem;
}
.login__check-label, .login__forgot, .login__register {
  font-size: var(--small-font-size);
  color: #333;
}
.login__check-group {
  column-gap: 0.5rem;
}
.login__check-input {
  width: 16px;
  height: 16px;
}
.login__forgot {
  color: var(--white-color);
}
.login__forgot:hover {
  text-decoration: underline;
}
.login__button {
  width: 100%;
  padding: 1rem;
  border-radius: 0.5rem;
  color: #333;
  background-color: var(--white-color);
  font-weight: var(--font-medium);
  border: 2px solid #333;
  cursor: pointer;
  margin-bottom: 2rem;
  transition: all .3s ease-in-out;
}

.login__button:hover{
  background-color: #333;
  border: 2px solid transparent;
  color: #fff;
} 

.login__register {
  text-align: center;
}
.login__register a {
  color: var(--white-color);
  font-weight: var(--font-medium);
  color: #333;
}
.login__register a:hover {
  text-decoration: underline;
}

/* Input focus move up label */
.login__input:focus + .login__label {
  top: -12px;
  font-size: var(--small-font-size);
  
}

/* Input focus sticky top label */
.login__input:not(:placeholder-shown).login__input:not(:focus) + .login__label {
  top: -12px;
  font-size: var(--small-font-size);
}

.stat-card .extra-stats {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out, visibility 0.5s;
}

.stat-card:hover .extra-stats {
  max-height: 1000px; /* Ensure this is large enough to reveal all content */
  opacity: 1;
  visibility: visible;
}

/*=============== BREAKPOINTS ===============*/
/* For medium devices */
/* @media screen and (min-width: 576px) {
  .login {
    justify-content: center;
  }
  .login__form {
    width: 432px;
    padding: 4rem 3rem 3.5rem;
    border-radius: 1.5rem;
  }
  .login__title {
    font-size: 2rem;
  }
} */